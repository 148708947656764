body {
  overflow: hidden;
  padding: 0px !important;
  margin: 0px !important;
  background-size: cover;
  background-color: #090e24;
  display: flex;
  flex-direction: column;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
}

canvas {
  margin-top: 0px;
  image-rendering: crisp-edges !important;
}

input {
  border-radius: 6px;
}

video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  top: 0;
  left: 0;
}

@font-face {
  font-family: 'ccwhatchamacallitbold';
  src:
    url('../assets/fonts/CCWhatchamacallit.woff2') format('woff2'),
    url('../assets/fonts/CCWhatchamacallit.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
